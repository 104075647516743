@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kulim+Park:wght@400;700&display=swap');

:root {
    /* --bg-body-color: #312E35; */

    --bg-body-color: #2A292E;
    /* 302F36 */
    --main-text-color: rgba(255, 255, 255, 0.8);
    --main-line-height: 40px;
    --accent-text-color: rgba(0, 196, 184, 1);

    --logo-accent-color: rgba(0, 196, 184, 0.3);

    --header-link-color: #fff;

    --link-color: #fff;

    --btn-with-accent-bg-color: background: linear-gradient(264.23deg, #00C4B8 4.42%, #0ACE88 98.24%);

    --popup-text-color: rgba(42, 41, 45, 1);

    --header-bg-color: rgba(38, 38, 42, 0.8);

    --main-text-size: 18px;
    --bold-p-text-size: 20px;
    --bold-p-line-height: 40px;

    --banner-p-font-size: 22px;
    --banner-p-line-height: 40px;


    --text-bold-font-size: 22px;
    --small-text-size: 16px;

    --descriptions-font-size: 22px;
    --descriptions-line-height: 40px;


    --h1-font-size: 112px;
    --h2-font-size: 40px;
    --h2-line-height: 48px;

    --btn-font-size: 16px;
    --more-btn-font-size: 14px;

    --popup-font-size: 14px;

    --source-font-size: 12px;
}

.accent-text-bg {
    /* position: relative; */
    background-color: var(--accent-text-color);
    background-size: 120%;
}




@media screen and (max-width: 768px) {
    :root {
        --main-text-size: 14px;
        --main-line-height: 24px;
        --bold-p-text-size: 16px;
        --bold-p-line-height: 24px;


        --text-bold-font-size: 22px;
        --small-text-size: 16px;

        --banner-p-font-size: 16px;
        --banner-p-line-height: 24px;

        --descriptions-font-size: 16px;
        --descriptions-line-height: 24px;


        --h1-font-size: 112px;
        --h2-font-size: 32px;
        --h2-line-height: 36px;

        --btn-font-size: 16px;
        --more-btn-font-size: 14px;

        --popup-font-size: 14px;

        --source-font-size: 12px;
    }
}

.divider {
    padding: 24px 0;
}

.divider:after {
    content: '';
    border-top: 1px dashed #515156;
    display: block;
}

.acctent {
    background-image: url('../images/acctent.svg');
    background-size: 100% 80%;
    background-repeat: no-repeat;
    background-position: bottom center;
    padding: 0 60px;
}

@media screen and (max-width: 1024px) {
    .acctent {
        padding: 0 30px;
    }
}

.disabled {
    cursor: no-drop;
    font-weight: 400 !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
ul,
p,
ol {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

button,
input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

html {
    /* width: 100%;
    max-width: 100vw;
    overflow-x: hidden; */
    /* overflow-x: hidden; */
    /* max-width: 100%; */
}

section {
    max-width: 100vw
        /* overflow-x: hidden; */
        /* overflow-y: initial; */
}

@-moz-document url-prefix() {
    body {
        /* background-color: #29272D !important; */

        /* | */
        /* v */
        /* background-color: #2A292E !important; */
    }
}



body {
    /* padding-top: 100px; */
    background-color: var(--bg-body-color);
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    /* max-width: 100vw;
    /* overflow-x: hidden;  */
    /* max-width: 100%; */
    color: var(--main-text-color);
    font-family: 'Inter', sans-serif;
    /* overflow-x: hidden; */
    font-size: var(--main-text-size);
    line-height: var(--main-line-height);
}



@-webkit-document url-prefix() {
    body {
        /* background-color: #2F2F34; */

        /* |
        v
        background-color: #302F35; */

        /* 302F35 */
    }
}




body {
    overflow: hidden;
}



.jBox-content {
    color: gray
}




#root {
    overflow-x: hidden;
    position: relative;
    width: 100%;
    height: 100vh;
    scroll-behavior: smooth;
}

.tooltip-span {
    cursor: pointer;
}

.header-wrap {
    position: absolute;
    /* position: fixed;
    width: 100vw;
    height: 100% */
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.js-container {
    position: relative;
}

.flex {
    display: flex;
}

.space-between {
    justify-content: space-between;
}

header {
    pointer-events: all;
    top: 0px;
    /* position: absolute; */
    position: sticky;
    width: 100%;
    background-color: var(--header-bg-color);
    backdrop-filter: blur(6px);
    z-index: 10;
    transition: all 400ms ease-in-out;
}

@media screen and (max-width:1024px) {
    header {
        background-color: unset;
        backdrop-filter: unset;
    }



    .scrolled header {
        background-color: var(--header-bg-color);
        backdrop-filter: blur(6px);
    }
}

main {
    flex: 1;
}

footer {
    /* height: 100px; */
    /* background-color: red; */
}

/* section {
    overflow-x: hidden;
} */

.container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1200px;
    /* overflow-x: hidden; */
}

/* .container>div {
    padding: 0 20px;
} */

.body.nav-open {
    overflow-y: hidden;
}

/* modificators */

.two-col {
    display: flex;
    column-gap: 32px;
}

.two-col .left {
    flex-basis: 45%;
}

.two-col .right {
    flex-basis: calc(100% - 43% - 32px);
}

.green-accent {
    color: var(--accent-text-color);
}

.h2 {
    color: #fff;
    font-weight: 800;
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);
    text-transform: uppercase;
}

.dashed-decoration {
    border-bottom: 1px dashed #fff;
    position: relative;
}

.bold-paragraph {
    font-weight: 600;
    font-size: var(--bold-p-text-size);
    line-height: var(--bold-p-line-height);
    color: #fff;
}



.regular-paragraph {
    font-weight: 400;
    font-size: var(--main-text-size);
    line-height: var(--main-line-height);
}

.pt-15vh {
    padding-top: 15vh;
}

.pb-15vh {
    padding-bottom: 15vh;
}

.pt-30vh {
    /* 336px */
    padding-top: 336px;
    /* padding-top: 30vh; */
}

.pb-30vh {
    /* 336px */
    padding-bottom: 336px;
}

.pt-15vh {
    padding-top: 15vh;
}

.pt-23vh {
    /* 250px */
    padding-top: 23vh;
}



.mt-48px {
    margin-top: 48px;
}

.pt-32px {
    padding-top: 32px;
}

.pt-16px {
    padding-top: 16px;
}

@media screen and (max-width: 768px) {
    .bold-paragraph {
        font-weight: 400;
    }

    .pt-32px {
        padding-top: 16px;
    }
}

.btn-first-type {
    display: inline-flex;
    align-items: center;
    color: var(--link-color);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    padding: 10px;
}

.btn-second-type {
    display: inline-flex;
    padding: 22px 24px;
    background: linear-gradient(264.23deg, #00C4B8 4.42%, #0ACE88 98.24%);
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.btn-first-type svg {
    margin-right: 10px;
}


/* logo */


.logo {

    /* background-image: url(../assets/logo_bg.png);
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--logo-accent-color);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 32px;
    line-height: 24px;
    letter-spacing: -0.09em; */
}

/* header */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
}

.header__list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    /* max-height: 100%; */
    /* overflow-y: scroll; */
}

.header__link {
    padding: 0px 20px;
    color: var(--header-link-color);
    display: block;
}

.sandwich {
    display: none;
}

.sandwich svg {
    display: block;
}

.sandwich,
.logo__wrap {
    position: relative;
    z-index: 1;
}

.logo svg {
    display: block;
}

.header .socials__list {
    display: none;
}

@media screen and (max-width: 1064px) {
    .header .socials__list {
        display: flex;
    }

    .header__nav {
        position: fixed;
        width: 100%;
        top: 0;
        padding-top: 0px;
        padding-bottom: 0px;
        /* top: 90px; */
        height: calc(0vh);
        overflow-y: hidden;
        left: 0;
        background-color: #29292ebe;
        transition: height 400ms ease-in-out, padding-top 400ms ease-in-out, padding-bottom 400ms ease-in-out;

    }

    .header {
        padding: 20px;
    }

    .nav-open .header__nav {
        padding-top: 70px;
        padding-bottom: 70px;
        height: calc(100vh);
        overflow-y: auto;
    }

    .header__list {
        margin-top: 40px;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;
    }

    .header__link {
        padding: 5px 10px;
    }

    .sandwich {
        display: block;
        margin-right: 10px;
        cursor: pointer;
    }

    .header {
        justify-content: flex-start;
    }
}

/* footer */



/* 

*/



.hover-container {
    position: relative;
}

.hover-target {
    position: relative;
    cursor: pointer;
    /* font-size: 2rem; */
}

.hover-popup {
    position: absolute;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
    /* bottom: 10px; */
    bottom: 100%;
    left: 0%;
    width: 40ch;
    /* max-width: ; */
    margin: min(1rem, 20px);
    font-size: .8rem;
    background-color: #fff;
    border-radius: 8px;
    padding: 1.5em;
    z-index: 42;
    /* transform: scale(0); */
    opacity: 0;
    transition: transform 200ms ease;
    transform-origin: 8% -10px;
    color: rgba(42, 41, 45, 0.6);
    pointer-events: none;

}

.hover-target:hover>.hover-popup,
.hover-target:focus>.hover-popup,
.hover-popup:hover {
    /* transform: scale(1); */
    pointer-events: all;
    opacity: 1;
}

.hover-popup :not(:first-child) {
    margin-top: 1rem;
}

.hover-popup span {
    color: rgb(200, 20, 0);
    font-weight: 700;
}

.hover-popup::before {
    /* This is the triangle/arrow */
    content: '';
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    transform: rotate(180deg);
    bottom: -10px;
    left: 10%;

}

.hover-popup::after {
    /* This is merely here to expand the hoverable area, as a buffer between the "Hover me" text and the popup. */
    content: '';
    position: absolute;
    top: -1rem;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

@media (prefers-reduced-motion: reduce) {

    *,
    ::before,
    ::after {
        animation-delay: -1ms !important;
        animation-duration: -1ms !important;
        animation-iteration-count: 1 !important;
        background-attachment: initial !important;
        scroll-behavior: auto !important;
        transition-duration: 0s !important;
        transition-delay: 0s !important;
    }
}


.tippy-box {
    background-color: white !important;
    color: #868786;
}

#tooltip {
    margin: 20px !important;
    margin-bottom: 5px !important;
    padding: 10px;
    border-radius: 10px;
    max-width: 400px;
    position: relative;
    /* display: none; */
    opacity: 0;
    background-color: #FFFFFF;
    color: black;
    pointer-events: none;
}

#tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid transparent;
    border-top: 10px solid white;
}

.tooltip-span:hover~#tooltip {
    /* display: block; */
    opacity: 1;
}

.tooltip-arrow,
.tooltip-arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: white;
    /* background: inherit; */
}

.tooltip-arrow {
    visibility: hidden;
}

.tooltip-container {
    /* background: white; */
    color: rgba(42, 41, 45, 0.6);
    /* padding: 5px; */
    font-size: 14px;
    border-radius: 4px;
}

.tooltip-arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
}

.tooltip-container[data-popper-placement^='top']>.tooltip-arrow {
    bottom: -4px;
}

.tooltip-container[data-popper-placement^='bottom']>.tooltip-arrow {
    top: -4px;
}

.tooltip-container[data-popper-placement^='left']>.tooltip-arrow {
    right: -4px;
}

.tooltip-container[data-popper-placement^='right']>.tooltip-arrow {
    left: -4px;
}

.tooltip-container {

    /* margin: 0 10px !important; */
}

.tooltip-content {
    background: white;
    padding: 12px 16px;
    margin: 0 10px;
    max-width: 360px;
    border-radius: 10px;
    line-height: 1.6;
}

.tooltip-hover {
    white-space: nowrap;
    cursor: pointer;
}


.swiper-button-prev {
    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important; */
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzg1OF8xNDkpIj4KPHBhdGggZD0iTTEwLjgyNzMgMTIuMDAwN0wxNS43NzczIDcuMDUwNzJMMTQuMzYzMyA1LjYzNjcyTDcuOTk5MzQgMTIuMDAwN0wxNC4zNjMzIDE4LjM2NDdMMTUuNzc3MyAxNi45NTA3TDEwLjgyNzMgMTIuMDAwN1oiIGZpbGw9IndoaXRlIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfODU4XzE0OSI+CjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0id2hpdGUiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDI0IDApIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    width: 30px;
    height: 30px;
}

.swiper-button-next {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzg1N18xNDYpIj4KPHBhdGggZD0iTTEzLjE3MjcgMTIuMDAwN0w4LjIyMjY2IDcuMDUwNzJMOS42MzY2NiA1LjYzNjcyTDE2LjAwMDcgMTIuMDAwN0w5LjYzNjY2IDE4LjM2NDdMOC4yMjI2NiAxNi45NTA3TDEzLjE3MjcgMTIuMDAwN1oiIGZpbGw9IndoaXRlIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfODU3XzE0NiI+CjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    width: 30px;
    height: 30px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    content: unset !important;
}

@media screen and (max-width: 1024px) {
    .section-scroll-btn {
        display: none;
    }
}