.firefly {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 15px;
    height: 15px;
    margin: -0.2vw 0 0 9.8vw;
    filter: blur(3px);
    animation: ease 200s alternate infinite;
    pointer-events: none;
    z-index: -1;
}

.firefly::before,
.firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
}

.firefly::before {
    background: #04C7A2;
    opacity: 0.4;
    animation: drift ease alternate infinite;
}

.firefly::after {
    background: #04C7A2;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #04C99F;
    animation: drift ease alternate infinite, flash ease infinite;
}

.firefly:nth-child(1) {
    animation-name: move1;
}

.firefly:nth-child(1)::before {
    animation-duration: 14s;
}

.firefly:nth-child(1)::after {
    animation-duration: 14s, 8239ms;
    animation-delay: 0ms, 3014ms;
}

@keyframes move1 {
    0% {
        transform: translateX(-18vw) translateY(-14vh) scale(0.58);
    }

    4.1666666667% {
        transform: translateX(40vw) translateY(20vh) scale(0.45);
    }

    8.3333333333% {
        transform: translateX(-17vw) translateY(-49vh) scale(0.89);
    }

    12.5% {
        transform: translateX(-32vw) translateY(9vh) scale(0.43);
    }

    16.6666666667% {
        transform: translateX(-49vw) translateY(30vh) scale(0.31);
    }

    20.8333333333% {
        transform: translateX(-13vw) translateY(20vh) scale(0.66);
    }

    25% {
        transform: translateX(45vw) translateY(50vh) scale(0.62);
    }

    29.1666666667% {
        transform: translateX(33vw) translateY(50vh) scale(0.96);
    }

    33.3333333333% {
        transform: translateX(12vw) translateY(-9vh) scale(0.89);
    }

    37.5% {
        transform: translateX(18vw) translateY(-44vh) scale(0.95);
    }

    41.6666666667% {
        transform: translateX(23vw) translateY(-40vh) scale(0.37);
    }

    45.8333333333% {
        transform: translateX(-21vw) translateY(41vh) scale(0.54);
    }

    50% {
        transform: translateX(-12vw) translateY(8vh) scale(0.81);
    }

    54.1666666667% {
        transform: translateX(46vw) translateY(-4vh) scale(0.27);
    }

    58.3333333333% {
        transform: translateX(4vw) translateY(12vh) scale(0.37);
    }

    62.5% {
        transform: translateX(-30vw) translateY(45vh) scale(0.6);
    }

    66.6666666667% {
        transform: translateX(-16vw) translateY(-16vh) scale(0.29);
    }

    70.8333333333% {
        transform: translateX(25vw) translateY(43vh) scale(0.7);
    }

    75% {
        transform: translateX(-18vw) translateY(-38vh) scale(0.26);
    }

    79.1666666667% {
        transform: translateX(-6vw) translateY(40vh) scale(0.86);
    }

    83.3333333333% {
        transform: translateX(-1vw) translateY(-20vh) scale(0.98);
    }

    87.5% {
        transform: translateX(-33vw) translateY(28vh) scale(0.45);
    }

    91.6666666667% {
        transform: translateX(13vw) translateY(-48vh) scale(0.68);
    }

    95.8333333333% {
        transform: translateX(-23vw) translateY(19vh) scale(0.71);
    }

    100% {
        transform: translateX(41vw) translateY(-14vh) scale(0.81);
    }
}

.firefly:nth-child(2) {
    animation-name: move2;
}

.firefly:nth-child(2)::before {
    animation-duration: 12s;
}

.firefly:nth-child(2)::after {
    animation-duration: 12s, 10659ms;
    animation-delay: 0ms, 6342ms;
}

@keyframes move2 {
    0% {
        transform: translateX(-48vw) translateY(26vh) scale(0.47);
    }

    4% {
        transform: translateX(-15vw) translateY(9vh) scale(0.69);
    }

    8% {
        transform: translateX(-41vw) translateY(19vh) scale(0.94);
    }

    12% {
        transform: translateX(-30vw) translateY(-8vh) scale(0.49);
    }

    16% {
        transform: translateX(11vw) translateY(26vh) scale(0.48);
    }

    20% {
        transform: translateX(-25vw) translateY(8vh) scale(0.57);
    }

    24% {
        transform: translateX(19vw) translateY(3vh) scale(0.8);
    }

    28% {
        transform: translateX(13vw) translateY(-16vh) scale(0.5);
    }

    32% {
        transform: translateX(-34vw) translateY(-47vh) scale(0.28);
    }

    36% {
        transform: translateX(43vw) translateY(-5vh) scale(0.29);
    }

    40% {
        transform: translateX(-25vw) translateY(43vh) scale(0.51);
    }

    44% {
        transform: translateX(6vw) translateY(-15vh) scale(0.35);
    }

    48% {
        transform: translateX(41vw) translateY(-18vh) scale(0.34);
    }

    52% {
        transform: translateX(41vw) translateY(5vh) scale(0.79);
    }

    56% {
        transform: translateX(29vw) translateY(22vh) scale(0.56);
    }

    60% {
        transform: translateX(7vw) translateY(-38vh) scale(0.93);
    }

    64% {
        transform: translateX(2vw) translateY(0vh) scale(0.47);
    }

    68% {
        transform: translateX(24vw) translateY(-2vh) scale(0.97);
    }

    72% {
        transform: translateX(-33vw) translateY(-21vh) scale(0.88);
    }

    76% {
        transform: translateX(-23vw) translateY(35vh) scale(0.6);
    }

    80% {
        transform: translateX(-5vw) translateY(-6vh) scale(0.79);
    }

    84% {
        transform: translateX(-40vw) translateY(-7vh) scale(0.3);
    }

    88% {
        transform: translateX(-32vw) translateY(7vh) scale(0.97);
    }

    92% {
        transform: translateX(-32vw) translateY(-8vh) scale(0.43);
    }

    96% {
        transform: translateX(3vw) translateY(-3vh) scale(0.28);
    }

    100% {
        transform: translateX(16vw) translateY(-19vh) scale(0.73);
    }
}

.firefly:nth-child(3) {
    animation-name: move3;
}

.firefly:nth-child(3)::before {
    animation-duration: 18s;
}

.firefly:nth-child(3)::after {
    animation-duration: 18s, 7255ms;
    animation-delay: 0ms, 7631ms;
}

@keyframes move3 {
    0% {
        transform: translateX(24vw) translateY(35vh) scale(0.45);
    }

    4.1666666667% {
        transform: translateX(26vw) translateY(8vh) scale(0.4);
    }

    8.3333333333% {
        transform: translateX(44vw) translateY(44vh) scale(0.81);
    }

    12.5% {
        transform: translateX(-49vw) translateY(-37vh) scale(0.78);
    }

    16.6666666667% {
        transform: translateX(-30vw) translateY(-16vh) scale(0.58);
    }

    20.8333333333% {
        transform: translateX(-1vw) translateY(-9vh) scale(0.44);
    }

    25% {
        transform: translateX(-17vw) translateY(28vh) scale(0.28);
    }

    29.1666666667% {
        transform: translateX(23vw) translateY(7vh) scale(0.48);
    }

    33.3333333333% {
        transform: translateX(-14vw) translateY(22vh) scale(0.74);
    }

    37.5% {
        transform: translateX(46vw) translateY(-21vh) scale(0.37);
    }

    41.6666666667% {
        transform: translateX(-34vw) translateY(13vh) scale(0.49);
    }

    45.8333333333% {
        transform: translateX(6vw) translateY(47vh) scale(0.33);
    }

    50% {
        transform: translateX(24vw) translateY(-18vh) scale(0.61);
    }

    54.1666666667% {
        transform: translateX(39vw) translateY(33vh) scale(0.3);
    }

    58.3333333333% {
        transform: translateX(-11vw) translateY(33vh) scale(0.81);
    }

    62.5% {
        transform: translateX(7vw) translateY(46vh) scale(0.57);
    }

    66.6666666667% {
        transform: translateX(50vw) translateY(35vh) scale(0.78);
    }

    70.8333333333% {
        transform: translateX(-6vw) translateY(-19vh) scale(0.68);
    }

    75% {
        transform: translateX(29vw) translateY(-19vh) scale(0.58);
    }

    79.1666666667% {
        transform: translateX(37vw) translateY(42vh) scale(0.79);
    }

    83.3333333333% {
        transform: translateX(29vw) translateY(-44vh) scale(0.47);
    }

    87.5% {
        transform: translateX(35vw) translateY(-1vh) scale(0.71);
    }

    91.6666666667% {
        transform: translateX(44vw) translateY(23vh) scale(0.29);
    }

    95.8333333333% {
        transform: translateX(37vw) translateY(25vh) scale(0.98);
    }

    100% {
        transform: translateX(-41vw) translateY(-29vh) scale(0.61);
    }
}

.firefly:nth-child(4) {
    animation-name: move4;
}

.firefly:nth-child(4)::before {
    animation-duration: 15s;
}

.firefly:nth-child(4)::after {
    animation-duration: 15s, 7039ms;
    animation-delay: 0ms, 4024ms;
}

@keyframes move4 {
    0% {
        transform: translateX(29vw) translateY(-46vh) scale(0.52);
    }

    5.8823529412% {
        transform: translateX(1vw) translateY(-27vh) scale(0.65);
    }

    11.7647058824% {
        transform: translateX(44vw) translateY(-11vh) scale(0.88);
    }

    17.6470588235% {
        transform: translateX(44vw) translateY(-32vh) scale(0.35);
    }

    23.5294117647% {
        transform: translateX(-17vw) translateY(20vh) scale(0.3);
    }

    29.4117647059% {
        transform: translateX(-6vw) translateY(34vh) scale(0.72);
    }

    35.2941176471% {
        transform: translateX(-12vw) translateY(-34vh) scale(0.51);
    }

    41.1764705882% {
        transform: translateX(24vw) translateY(10vh) scale(0.69);
    }

    47.0588235294% {
        transform: translateX(46vw) translateY(-44vh) scale(0.78);
    }

    52.9411764706% {
        transform: translateX(-37vw) translateY(-30vh) scale(0.53);
    }

    58.8235294118% {
        transform: translateX(-26vw) translateY(15vh) scale(0.84);
    }

    64.7058823529% {
        transform: translateX(20vw) translateY(-46vh) scale(0.72);
    }

    70.5882352941% {
        transform: translateX(-6vw) translateY(-47vh) scale(1);
    }

    76.4705882353% {
        transform: translateX(-4vw) translateY(-5vh) scale(0.44);
    }

    82.3529411765% {
        transform: translateX(23vw) translateY(-21vh) scale(0.34);
    }

    88.2352941176% {
        transform: translateX(35vw) translateY(37vh) scale(0.73);
    }

    94.1176470588% {
        transform: translateX(18vw) translateY(-12vh) scale(0.68);
    }

    100% {
        transform: translateX(25vw) translateY(-21vh) scale(0.45);
    }
}

.firefly:nth-child(5) {
    animation-name: move5;
}

.firefly:nth-child(5)::before {
    animation-duration: 17s;
}

.firefly:nth-child(5)::after {
    animation-duration: 17s, 8790ms;
    animation-delay: 0ms, 1818ms;
}

@keyframes move5 {
    0% {
        transform: translateX(12vw) translateY(43vh) scale(0.72);
    }

    4.7619047619% {
        transform: translateX(18vw) translateY(24vh) scale(0.9);
    }

    9.5238095238% {
        transform: translateX(-36vw) translateY(-34vh) scale(0.96);
    }

    14.2857142857% {
        transform: translateX(-33vw) translateY(-2vh) scale(0.27);
    }

    19.0476190476% {
        transform: translateX(45vw) translateY(8vh) scale(0.95);
    }

    23.8095238095% {
        transform: translateX(-19vw) translateY(-38vh) scale(0.8);
    }

    28.5714285714% {
        transform: translateX(-43vw) translateY(39vh) scale(0.69);
    }

    33.3333333333% {
        transform: translateX(14vw) translateY(-8vh) scale(0.54);
    }

    38.0952380952% {
        transform: translateX(-40vw) translateY(12vh) scale(0.46);
    }

    42.8571428571% {
        transform: translateX(49vw) translateY(-48vh) scale(0.96);
    }

    47.619047619% {
        transform: translateX(-33vw) translateY(48vh) scale(0.59);
    }

    52.380952381% {
        transform: translateX(-16vw) translateY(48vh) scale(0.39);
    }

    57.1428571429% {
        transform: translateX(38vw) translateY(-32vh) scale(0.7);
    }

    61.9047619048% {
        transform: translateX(-43vw) translateY(-44vh) scale(0.58);
    }

    66.6666666667% {
        transform: translateX(4vw) translateY(3vh) scale(0.71);
    }

    71.4285714286% {
        transform: translateX(-5vw) translateY(-12vh) scale(0.49);
    }

    76.1904761905% {
        transform: translateX(-13vw) translateY(-23vh) scale(0.83);
    }

    80.9523809524% {
        transform: translateX(-47vw) translateY(-16vh) scale(0.82);
    }

    85.7142857143% {
        transform: translateX(24vw) translateY(-35vh) scale(0.63);
    }

    90.4761904762% {
        transform: translateX(7vw) translateY(-36vh) scale(0.37);
    }

    95.2380952381% {
        transform: translateX(19vw) translateY(-34vh) scale(0.88);
    }

    100% {
        transform: translateX(1vw) translateY(-43vh) scale(0.53);
    }
}

.firefly:nth-child(6) {
    animation-name: move6;
}

.firefly:nth-child(6)::before {
    animation-duration: 9s;
}

.firefly:nth-child(6)::after {
    animation-duration: 9s, 6632ms;
    animation-delay: 0ms, 3095ms;
}

@keyframes move6 {
    0% {
        transform: translateX(8vw) translateY(21vh) scale(0.86);
    }

    5.2631578947% {
        transform: translateX(-45vw) translateY(-45vh) scale(0.75);
    }

    10.5263157895% {
        transform: translateX(-33vw) translateY(-43vh) scale(0.85);
    }

    15.7894736842% {
        transform: translateX(16vw) translateY(-36vh) scale(0.74);
    }

    21.0526315789% {
        transform: translateX(-4vw) translateY(19vh) scale(0.64);
    }

    26.3157894737% {
        transform: translateX(30vw) translateY(-39vh) scale(0.65);
    }

    31.5789473684% {
        transform: translateX(-13vw) translateY(30vh) scale(0.44);
    }

    36.8421052632% {
        transform: translateX(-15vw) translateY(-45vh) scale(0.41);
    }

    42.1052631579% {
        transform: translateX(-39vw) translateY(-36vh) scale(0.59);
    }

    47.3684210526% {
        transform: translateX(27vw) translateY(37vh) scale(0.27);
    }

    52.6315789474% {
        transform: translateX(-1vw) translateY(-25vh) scale(0.58);
    }

    57.8947368421% {
        transform: translateX(-13vw) translateY(27vh) scale(0.4);
    }

    63.1578947368% {
        transform: translateX(2vw) translateY(27vh) scale(0.68);
    }

    68.4210526316% {
        transform: translateX(-14vw) translateY(-3vh) scale(0.4);
    }

    73.6842105263% {
        transform: translateX(-10vw) translateY(-23vh) scale(0.46);
    }

    78.9473684211% {
        transform: translateX(-15vw) translateY(-3vh) scale(0.83);
    }

    84.2105263158% {
        transform: translateX(7vw) translateY(-30vh) scale(0.54);
    }

    89.4736842105% {
        transform: translateX(-37vw) translateY(-4vh) scale(0.73);
    }

    94.7368421053% {
        transform: translateX(38vw) translateY(21vh) scale(0.44);
    }

    100% {
        transform: translateX(12vw) translateY(-18vh) scale(0.36);
    }
}

.firefly:nth-child(7) {
    animation-name: move7;
}

.firefly:nth-child(7)::before {
    animation-duration: 13s;
}

.firefly:nth-child(7)::after {
    animation-duration: 13s, 9316ms;
    animation-delay: 0ms, 827ms;
}

@keyframes move7 {
    0% {
        transform: translateX(4vw) translateY(20vh) scale(0.99);
    }

    4.5454545455% {
        transform: translateX(33vw) translateY(32vh) scale(0.56);
    }

    9.0909090909% {
        transform: translateX(49vw) translateY(26vh) scale(0.9);
    }

    13.6363636364% {
        transform: translateX(-45vw) translateY(-5vh) scale(0.57);
    }

    18.1818181818% {
        transform: translateX(-33vw) translateY(2vh) scale(0.91);
    }

    22.7272727273% {
        transform: translateX(34vw) translateY(-9vh) scale(0.31);
    }

    27.2727272727% {
        transform: translateX(-26vw) translateY(-39vh) scale(0.26);
    }

    31.8181818182% {
        transform: translateX(-17vw) translateY(28vh) scale(0.99);
    }

    36.3636363636% {
        transform: translateX(-3vw) translateY(50vh) scale(0.62);
    }

    40.9090909091% {
        transform: translateX(-33vw) translateY(-18vh) scale(0.6);
    }

    45.4545454545% {
        transform: translateX(13vw) translateY(-21vh) scale(0.68);
    }

    50% {
        transform: translateX(16vw) translateY(-36vh) scale(0.42);
    }

    54.5454545455% {
        transform: translateX(4vw) translateY(15vh) scale(0.6);
    }

    59.0909090909% {
        transform: translateX(0vw) translateY(10vh) scale(0.37);
    }

    63.6363636364% {
        transform: translateX(-7vw) translateY(-20vh) scale(0.88);
    }

    68.1818181818% {
        transform: translateX(16vw) translateY(-6vh) scale(0.42);
    }

    72.7272727273% {
        transform: translateX(34vw) translateY(16vh) scale(0.69);
    }

    77.2727272727% {
        transform: translateX(35vw) translateY(20vh) scale(0.51);
    }

    81.8181818182% {
        transform: translateX(22vw) translateY(-22vh) scale(0.86);
    }

    86.3636363636% {
        transform: translateX(-36vw) translateY(-19vh) scale(0.97);
    }

    90.9090909091% {
        transform: translateX(37vw) translateY(16vh) scale(0.76);
    }

    95.4545454545% {
        transform: translateX(27vw) translateY(-34vh) scale(0.35);
    }

    100% {
        transform: translateX(48vw) translateY(-39vh) scale(0.86);
    }
}

.firefly:nth-child(8) {
    animation-name: move8;
}

.firefly:nth-child(8)::before {
    animation-duration: 9s;
}

.firefly:nth-child(8)::after {
    animation-duration: 9s, 10031ms;
    animation-delay: 0ms, 8037ms;
}

@keyframes move8 {
    0% {
        transform: translateX(-47vw) translateY(-20vh) scale(0.27);
    }

    4.1666666667% {
        transform: translateX(-23vw) translateY(22vh) scale(0.72);
    }

    8.3333333333% {
        transform: translateX(24vw) translateY(8vh) scale(0.72);
    }

    12.5% {
        transform: translateX(-26vw) translateY(-44vh) scale(0.52);
    }

    16.6666666667% {
        transform: translateX(-10vw) translateY(16vh) scale(0.37);
    }

    20.8333333333% {
        transform: translateX(25vw) translateY(-16vh) scale(0.6);
    }

    25% {
        transform: translateX(-1vw) translateY(-15vh) scale(0.51);
    }

    29.1666666667% {
        transform: translateX(3vw) translateY(0vh) scale(0.26);
    }

    33.3333333333% {
        transform: translateX(11vw) translateY(42vh) scale(0.82);
    }

    37.5% {
        transform: translateX(10vw) translateY(-27vh) scale(0.53);
    }

    41.6666666667% {
        transform: translateX(0vw) translateY(20vh) scale(0.92);
    }

    45.8333333333% {
        transform: translateX(0vw) translateY(-5vh) scale(0.35);
    }

    50% {
        transform: translateX(-42vw) translateY(36vh) scale(0.59);
    }

    54.1666666667% {
        transform: translateX(-6vw) translateY(-47vh) scale(0.71);
    }

    58.3333333333% {
        transform: translateX(-25vw) translateY(-28vh) scale(0.36);
    }

    62.5% {
        transform: translateX(32vw) translateY(0vh) scale(0.3);
    }

    66.6666666667% {
        transform: translateX(50vw) translateY(34vh) scale(0.29);
    }

    70.8333333333% {
        transform: translateX(-25vw) translateY(-7vh) scale(0.27);
    }

    75% {
        transform: translateX(-2vw) translateY(-9vh) scale(0.3);
    }

    79.1666666667% {
        transform: translateX(18vw) translateY(-18vh) scale(0.29);
    }

    83.3333333333% {
        transform: translateX(-23vw) translateY(-25vh) scale(0.85);
    }

    87.5% {
        transform: translateX(-35vw) translateY(-43vh) scale(0.3);
    }

    91.6666666667% {
        transform: translateX(-1vw) translateY(13vh) scale(0.87);
    }

    95.8333333333% {
        transform: translateX(9vw) translateY(-29vh) scale(0.6);
    }

    100% {
        transform: translateX(43vw) translateY(-25vh) scale(0.68);
    }
}

.firefly:nth-child(9) {
    animation-name: move9;
}

.firefly:nth-child(9)::before {
    animation-duration: 17s;
}

.firefly:nth-child(9)::after {
    animation-duration: 17s, 7996ms;
    animation-delay: 0ms, 6165ms;
}

@keyframes move9 {
    0% {
        transform: translateX(-17vw) translateY(7vh) scale(0.43);
    }

    3.7037037037% {
        transform: translateX(-29vw) translateY(9vh) scale(0.41);
    }

    7.4074074074% {
        transform: translateX(-9vw) translateY(23vh) scale(0.47);
    }

    11.1111111111% {
        transform: translateX(-6vw) translateY(2vh) scale(0.88);
    }

    14.8148148148% {
        transform: translateX(-26vw) translateY(37vh) scale(0.75);
    }

    18.5185185185% {
        transform: translateX(24vw) translateY(0vh) scale(0.84);
    }

    22.2222222222% {
        transform: translateX(40vw) translateY(-29vh) scale(0.31);
    }

    25.9259259259% {
        transform: translateX(-32vw) translateY(31vh) scale(0.84);
    }

    29.6296296296% {
        transform: translateX(-28vw) translateY(5vh) scale(0.77);
    }

    33.3333333333% {
        transform: translateX(-14vw) translateY(-21vh) scale(0.79);
    }

    37.037037037% {
        transform: translateX(26vw) translateY(3vh) scale(0.37);
    }

    40.7407407407% {
        transform: translateX(-47vw) translateY(-10vh) scale(0.98);
    }

    44.4444444444% {
        transform: translateX(19vw) translateY(-4vh) scale(0.86);
    }

    48.1481481481% {
        transform: translateX(32vw) translateY(35vh) scale(0.76);
    }

    51.8518518519% {
        transform: translateX(-13vw) translateY(-29vh) scale(0.81);
    }

    55.5555555556% {
        transform: translateX(16vw) translateY(-29vh) scale(0.65);
    }

    59.2592592593% {
        transform: translateX(-48vw) translateY(33vh) scale(0.68);
    }

    62.962962963% {
        transform: translateX(-42vw) translateY(-17vh) scale(0.88);
    }

    66.6666666667% {
        transform: translateX(24vw) translateY(-44vh) scale(0.88);
    }

    70.3703703704% {
        transform: translateX(-21vw) translateY(-6vh) scale(0.63);
    }

    74.0740740741% {
        transform: translateX(38vw) translateY(-5vh) scale(0.82);
    }

    77.7777777778% {
        transform: translateX(10vw) translateY(-23vh) scale(0.91);
    }

    81.4814814815% {
        transform: translateX(20vw) translateY(47vh) scale(0.78);
    }

    85.1851851852% {
        transform: translateX(-22vw) translateY(-41vh) scale(0.73);
    }

    88.8888888889% {
        transform: translateX(-5vw) translateY(2vh) scale(0.7);
    }

    92.5925925926% {
        transform: translateX(22vw) translateY(-44vh) scale(0.47);
    }

    96.2962962963% {
        transform: translateX(36vw) translateY(38vh) scale(0.67);
    }

    100% {
        transform: translateX(41vw) translateY(-48vh) scale(1);
    }
}

.firefly:nth-child(10) {
    animation-name: move10;
}

.firefly:nth-child(10)::before {
    animation-duration: 9s;
}

.firefly:nth-child(10)::after {
    animation-duration: 9s, 8257ms;
    animation-delay: 0ms, 7735ms;
}

@keyframes move10 {
    0% {
        transform: translateX(36vw) translateY(-5vh) scale(0.3);
    }

    3.8461538462% {
        transform: translateX(47vw) translateY(3vh) scale(0.45);
    }

    7.6923076923% {
        transform: translateX(-20vw) translateY(16vh) scale(0.27);
    }

    11.5384615385% {
        transform: translateX(17vw) translateY(-11vh) scale(0.8);
    }

    15.3846153846% {
        transform: translateX(10vw) translateY(-14vh) scale(0.77);
    }

    19.2307692308% {
        transform: translateX(45vw) translateY(-27vh) scale(0.49);
    }

    23.0769230769% {
        transform: translateX(50vw) translateY(1vh) scale(0.42);
    }

    26.9230769231% {
        transform: translateX(4vw) translateY(-36vh) scale(0.81);
    }

    30.7692307692% {
        transform: translateX(-4vw) translateY(49vh) scale(0.5);
    }

    34.6153846154% {
        transform: translateX(34vw) translateY(-27vh) scale(0.47);
    }

    38.4615384615% {
        transform: translateX(-33vw) translateY(-28vh) scale(0.32);
    }

    42.3076923077% {
        transform: translateX(34vw) translateY(-44vh) scale(0.76);
    }

    46.1538461538% {
        transform: translateX(1vw) translateY(36vh) scale(0.54);
    }

    50% {
        transform: translateX(13vw) translateY(-3vh) scale(0.63);
    }

    53.8461538462% {
        transform: translateX(4vw) translateY(-35vh) scale(0.91);
    }

    57.6923076923% {
        transform: translateX(-38vw) translateY(42vh) scale(0.79);
    }

    61.5384615385% {
        transform: translateX(15vw) translateY(27vh) scale(0.27);
    }

    65.3846153846% {
        transform: translateX(49vw) translateY(-15vh) scale(0.71);
    }

    69.2307692308% {
        transform: translateX(14vw) translateY(-43vh) scale(0.4);
    }

    73.0769230769% {
        transform: translateX(-48vw) translateY(38vh) scale(0.76);
    }

    76.9230769231% {
        transform: translateX(37vw) translateY(2vh) scale(0.38);
    }

    80.7692307692% {
        transform: translateX(-28vw) translateY(-48vh) scale(0.33);
    }

    84.6153846154% {
        transform: translateX(6vw) translateY(-18vh) scale(0.51);
    }

    88.4615384615% {
        transform: translateX(15vw) translateY(48vh) scale(0.52);
    }

    92.3076923077% {
        transform: translateX(47vw) translateY(48vh) scale(0.61);
    }

    96.1538461538% {
        transform: translateX(-15vw) translateY(18vh) scale(0.41);
    }

    100% {
        transform: translateX(-42vw) translateY(21vh) scale(0.94);
    }
}

.firefly:nth-child(11) {
    animation-name: move11;
}

.firefly:nth-child(11)::before {
    animation-duration: 15s;
}

.firefly:nth-child(11)::after {
    animation-duration: 15s, 10180ms;
    animation-delay: 0ms, 5742ms;
}

@keyframes move11 {
    0% {
        transform: translateX(19vw) translateY(43vh) scale(0.85);
    }

    5% {
        transform: translateX(28vw) translateY(11vh) scale(0.54);
    }

    10% {
        transform: translateX(48vw) translateY(9vh) scale(0.75);
    }

    15% {
        transform: translateX(-47vw) translateY(6vh) scale(0.83);
    }

    20% {
        transform: translateX(-31vw) translateY(-15vh) scale(0.9);
    }

    25% {
        transform: translateX(-15vw) translateY(1vh) scale(0.71);
    }

    30% {
        transform: translateX(34vw) translateY(50vh) scale(0.32);
    }

    35% {
        transform: translateX(-5vw) translateY(40vh) scale(0.64);
    }

    40% {
        transform: translateX(10vw) translateY(-18vh) scale(0.82);
    }

    45% {
        transform: translateX(4vw) translateY(8vh) scale(0.33);
    }

    50% {
        transform: translateX(-25vw) translateY(8vh) scale(0.63);
    }

    55% {
        transform: translateX(-6vw) translateY(22vh) scale(0.36);
    }

    60% {
        transform: translateX(37vw) translateY(24vh) scale(0.31);
    }

    65% {
        transform: translateX(-21vw) translateY(-13vh) scale(0.58);
    }

    70% {
        transform: translateX(-22vw) translateY(38vh) scale(0.89);
    }

    75% {
        transform: translateX(44vw) translateY(25vh) scale(0.4);
    }

    80% {
        transform: translateX(24vw) translateY(-20vh) scale(0.67);
    }

    85% {
        transform: translateX(18vw) translateY(-9vh) scale(0.8);
    }

    90% {
        transform: translateX(42vw) translateY(-3vh) scale(0.27);
    }

    95% {
        transform: translateX(50vw) translateY(-24vh) scale(0.42);
    }

    100% {
        transform: translateX(37vw) translateY(33vh) scale(0.64);
    }
}

.firefly:nth-child(12) {
    animation-name: move12;
}

.firefly:nth-child(12)::before {
    animation-duration: 10s;
}

.firefly:nth-child(12)::after {
    animation-duration: 10s, 7034ms;
    animation-delay: 0ms, 7337ms;
}

@keyframes move12 {
    0% {
        transform: translateX(-19vw) translateY(38vh) scale(0.4);
    }

    3.8461538462% {
        transform: translateX(30vw) translateY(-18vh) scale(0.76);
    }

    7.6923076923% {
        transform: translateX(27vw) translateY(7vh) scale(0.4);
    }

    11.5384615385% {
        transform: translateX(31vw) translateY(-26vh) scale(0.44);
    }

    15.3846153846% {
        transform: translateX(-18vw) translateY(29vh) scale(0.87);
    }

    19.2307692308% {
        transform: translateX(-13vw) translateY(-22vh) scale(0.77);
    }

    23.0769230769% {
        transform: translateX(-11vw) translateY(7vh) scale(0.85);
    }

    26.9230769231% {
        transform: translateX(-36vw) translateY(-46vh) scale(0.96);
    }

    30.7692307692% {
        transform: translateX(-42vw) translateY(-33vh) scale(0.28);
    }

    34.6153846154% {
        transform: translateX(-27vw) translateY(-35vh) scale(0.33);
    }

    38.4615384615% {
        transform: translateX(10vw) translateY(-17vh) scale(0.62);
    }

    42.3076923077% {
        transform: translateX(24vw) translateY(-25vh) scale(0.43);
    }

    46.1538461538% {
        transform: translateX(-2vw) translateY(-11vh) scale(0.42);
    }

    50% {
        transform: translateX(-33vw) translateY(-42vh) scale(0.44);
    }

    53.8461538462% {
        transform: translateX(9vw) translateY(46vh) scale(0.58);
    }

    57.6923076923% {
        transform: translateX(44vw) translateY(-11vh) scale(0.29);
    }

    61.5384615385% {
        transform: translateX(-43vw) translateY(32vh) scale(0.47);
    }

    65.3846153846% {
        transform: translateX(-8vw) translateY(-2vh) scale(0.43);
    }

    69.2307692308% {
        transform: translateX(8vw) translateY(-27vh) scale(0.85);
    }

    73.0769230769% {
        transform: translateX(-35vw) translateY(-37vh) scale(0.48);
    }

    76.9230769231% {
        transform: translateX(24vw) translateY(37vh) scale(0.34);
    }

    80.7692307692% {
        transform: translateX(24vw) translateY(50vh) scale(0.49);
    }

    84.6153846154% {
        transform: translateX(-32vw) translateY(-19vh) scale(0.48);
    }

    88.4615384615% {
        transform: translateX(-33vw) translateY(-21vh) scale(0.44);
    }

    92.3076923077% {
        transform: translateX(29vw) translateY(-23vh) scale(0.93);
    }

    96.1538461538% {
        transform: translateX(19vw) translateY(-27vh) scale(0.58);
    }

    100% {
        transform: translateX(43vw) translateY(-38vh) scale(0.61);
    }
}

.firefly:nth-child(13) {
    animation-name: move13;
}

.firefly:nth-child(13)::before {
    animation-duration: 10s;
}

.firefly:nth-child(13)::after {
    animation-duration: 10s, 10650ms;
    animation-delay: 0ms, 3463ms;
}

@keyframes move13 {
    0% {
        transform: translateX(-8vw) translateY(-45vh) scale(0.9);
    }

    4.7619047619% {
        transform: translateX(35vw) translateY(37vh) scale(0.84);
    }

    9.5238095238% {
        transform: translateX(18vw) translateY(23vh) scale(0.62);
    }

    14.2857142857% {
        transform: translateX(25vw) translateY(42vh) scale(0.87);
    }

    19.0476190476% {
        transform: translateX(-23vw) translateY(34vh) scale(0.47);
    }

    23.8095238095% {
        transform: translateX(-46vw) translateY(-31vh) scale(0.75);
    }

    28.5714285714% {
        transform: translateX(-27vw) translateY(8vh) scale(0.32);
    }

    33.3333333333% {
        transform: translateX(38vw) translateY(-19vh) scale(0.44);
    }

    38.0952380952% {
        transform: translateX(5vw) translateY(39vh) scale(0.7);
    }

    42.8571428571% {
        transform: translateX(22vw) translateY(-1vh) scale(0.66);
    }

    47.619047619% {
        transform: translateX(-14vw) translateY(45vh) scale(0.67);
    }

    52.380952381% {
        transform: translateX(22vw) translateY(12vh) scale(0.9);
    }

    57.1428571429% {
        transform: translateX(-43vw) translateY(23vh) scale(0.96);
    }

    61.9047619048% {
        transform: translateX(-9vw) translateY(-47vh) scale(0.3);
    }

    66.6666666667% {
        transform: translateX(7vw) translateY(-49vh) scale(0.89);
    }

    71.4285714286% {
        transform: translateX(-22vw) translateY(43vh) scale(0.48);
    }

    76.1904761905% {
        transform: translateX(-36vw) translateY(-43vh) scale(0.52);
    }

    80.9523809524% {
        transform: translateX(-8vw) translateY(32vh) scale(0.89);
    }

    85.7142857143% {
        transform: translateX(20vw) translateY(50vh) scale(0.87);
    }

    90.4761904762% {
        transform: translateX(-45vw) translateY(-27vh) scale(0.34);
    }

    95.2380952381% {
        transform: translateX(-43vw) translateY(27vh) scale(0.49);
    }

    100% {
        transform: translateX(40vw) translateY(-43vh) scale(0.77);
    }
}

.firefly:nth-child(14) {
    animation-name: move14;
}

.firefly:nth-child(14)::before {
    animation-duration: 16s;
}

.firefly:nth-child(14)::after {
    animation-duration: 16s, 9741ms;
    animation-delay: 0ms, 6997ms;
}

@keyframes move14 {
    0% {
        transform: translateX(-47vw) translateY(9vh) scale(0.41);
    }

    5.2631578947% {
        transform: translateX(-46vw) translateY(-48vh) scale(0.51);
    }

    10.5263157895% {
        transform: translateX(-32vw) translateY(49vh) scale(0.83);
    }

    15.7894736842% {
        transform: translateX(-37vw) translateY(24vh) scale(0.91);
    }

    21.0526315789% {
        transform: translateX(30vw) translateY(-6vh) scale(0.88);
    }

    26.3157894737% {
        transform: translateX(38vw) translateY(-24vh) scale(0.26);
    }

    31.5789473684% {
        transform: translateX(-42vw) translateY(24vh) scale(0.67);
    }

    36.8421052632% {
        transform: translateX(-4vw) translateY(13vh) scale(0.78);
    }

    42.1052631579% {
        transform: translateX(5vw) translateY(-32vh) scale(0.27);
    }

    47.3684210526% {
        transform: translateX(12vw) translateY(43vh) scale(0.92);
    }

    52.6315789474% {
        transform: translateX(-6vw) translateY(46vh) scale(0.77);
    }

    57.8947368421% {
        transform: translateX(-7vw) translateY(35vh) scale(0.66);
    }

    63.1578947368% {
        transform: translateX(23vw) translateY(-43vh) scale(0.59);
    }

    68.4210526316% {
        transform: translateX(31vw) translateY(-36vh) scale(0.47);
    }

    73.6842105263% {
        transform: translateX(46vw) translateY(23vh) scale(0.67);
    }

    78.9473684211% {
        transform: translateX(-45vw) translateY(-16vh) scale(0.44);
    }

    84.2105263158% {
        transform: translateX(37vw) translateY(-8vh) scale(0.88);
    }

    89.4736842105% {
        transform: translateX(-45vw) translateY(-30vh) scale(0.56);
    }

    94.7368421053% {
        transform: translateX(0vw) translateY(21vh) scale(0.88);
    }

    100% {
        transform: translateX(-42vw) translateY(4vh) scale(0.8);
    }
}

.firefly:nth-child(15) {
    animation-name: move15;
}

.firefly:nth-child(15)::before {
    animation-duration: 18s;
}

.firefly:nth-child(15)::after {
    animation-duration: 18s, 9504ms;
    animation-delay: 0ms, 2476ms;
}

@keyframes move15 {
    0% {
        transform: translateX(-42vw) translateY(8vh) scale(0.35);
    }

    5.2631578947% {
        transform: translateX(32vw) translateY(-33vh) scale(0.82);
    }

    10.5263157895% {
        transform: translateX(-15vw) translateY(33vh) scale(0.62);
    }

    15.7894736842% {
        transform: translateX(-41vw) translateY(2vh) scale(0.32);
    }

    21.0526315789% {
        transform: translateX(29vw) translateY(-21vh) scale(0.42);
    }

    26.3157894737% {
        transform: translateX(24vw) translateY(-11vh) scale(0.46);
    }

    31.5789473684% {
        transform: translateX(-42vw) translateY(-31vh) scale(0.62);
    }

    36.8421052632% {
        transform: translateX(-6vw) translateY(18vh) scale(0.29);
    }

    42.1052631579% {
        transform: translateX(-29vw) translateY(-11vh) scale(0.39);
    }

    47.3684210526% {
        transform: translateX(37vw) translateY(43vh) scale(0.54);
    }

    52.6315789474% {
        transform: translateX(34vw) translateY(21vh) scale(0.55);
    }

    57.8947368421% {
        transform: translateX(41vw) translateY(43vh) scale(0.41);
    }

    63.1578947368% {
        transform: translateX(-31vw) translateY(-7vh) scale(0.88);
    }

    68.4210526316% {
        transform: translateX(22vw) translateY(-8vh) scale(0.79);
    }

    73.6842105263% {
        transform: translateX(22vw) translateY(28vh) scale(0.38);
    }

    78.9473684211% {
        transform: translateX(46vw) translateY(-26vh) scale(0.51);
    }

    84.2105263158% {
        transform: translateX(-42vw) translateY(-34vh) scale(0.95);
    }

    89.4736842105% {
        transform: translateX(-33vw) translateY(-42vh) scale(0.44);
    }

    94.7368421053% {
        transform: translateX(30vw) translateY(-29vh) scale(0.56);
    }

    100% {
        transform: translateX(44vw) translateY(37vh) scale(0.49);
    }
}

@keyframes drift {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes flash {

    0%,
    30%,
    100% {
        opacity: 0;
        box-shadow: 0 0 0vw 0vw #04C7A2;
    }

    5% {
        opacity: 1;
        box-shadow: 0 0 2vw 0.4vw #04C7A2;
    }
}